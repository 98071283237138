import React from "react";
import { Button, Founder, getPathname, Link } from "@italymondo/core-ui";
import { useStaticAboutQuery } from "./query";

export function About(): React.ReactElement | null {
  const data = useStaticAboutQuery();

  if (!data) {
    return null;
  }

  function ctaLink(url: string): React.ReactElement {
    return (
      <Link to={url}>
        <Button color="primary" variant="contained">
          {data.site?.page?.aboutCta?.title}
        </Button>
      </Link>
    );
  }

  return (
    <Founder
      actions={ctaLink(getPathname(data.site?.page?.aboutCta?.page?.url))}
      alignCenter={false}
      description={data.site?.page?.aboutParagraph || ""}
      media={data.site?.page?.aboutMedia[0]}
      mediaPlacement={data.site?.page?.aboutMediaPlacement}
      sectionName={data.site?.page?.aboutSectionName || ""}
      title={data.site?.page?.aboutTitle || ""}
    />
  );
}
