import { PpIntroduction } from "@italymondo/core-ui";
import { CMSIntroductionData } from "./query";

export function formatData(data: CMSIntroductionData): PpIntroduction {
  if (!data.site) {
    throw new Error("Expected a site object");
  }
  const { site } = data;

  return {
    media: site?.page?.introMedia[0],
    mediaPlacement: site?.page?.introMediaPlacement,
    paragraph: site?.page?.introParagraph || "",
    shadowHeader: site?.page?.introShadowHeader || "",
  };
}
