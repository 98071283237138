import React from "react";
import {
  ContactForm as ContactFormComponent,
  getPathname,
} from "@italymondo/core-ui";
import { useStaticContactFormQuery } from "./query";

export function ContactForm(): React.ReactElement | null {
  const data = useStaticContactFormQuery();

  if (!data) {
    return null;
  }

  return (
    <ContactFormComponent
      confirmationPageUrl={
        getPathname(data.site.page?.footerContactForm?.confirmationPage?.url) ||
        "/"
      }
      description={data.site.page?.footerContactForm?.description || ""}
      sectionName={data.site.page?.contactFormSectionName || ""}
      title={data.site.page?.footerContactForm?.title || ""}
    />
  );
}
