import React from "react";
import { Process } from "@italymondo/core-ui";
import { useStaticOurProcessQuery } from "./query";

export function OurProcess(): React.ReactElement | null {
  const data = useStaticOurProcessQuery();

  if (!data) {
    return null;
  }

  return (
    <Process
      ctaLink={data.page?.process?.cta}
      description={data.page?.process.description}
      media={data.page?.processMedia[0]}
      mediaPlacement={data.page?.processMediaPlacement}
      sectionName={data.page?.processSectionName || ""}
      steps={data.page?.process.steps.map((step) => ({
        description: step.description,
        shadowHeader: step.step,
        title: step.name,
      }))}
      title={data.page?.process.title}
    />
  );
}
