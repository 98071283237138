import { graphql, useStaticQuery } from "gatsby";
import { HomePageTransitionalCTAQuery } from "./query.generated";

export type CMSTransitionalCTAData =
  HomePageTransitionalCTAQuery["cms"]["site"];

export function useStaticTransitionalCTAQuery(): CMSTransitionalCTAData {
  const data = useStaticQuery<HomePageTransitionalCTAQuery>(
    graphql`
      query HomePageTransitionalCTA {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                transitionalCta {
                  buttonText
                  description
                  shadowImage {
                    url
                  }
                  title
                  action {
                    ... on CMS_CollectEmailBlock {
                      __typename
                      collectEmail
                    }
                    ... on CMS_LinkPageBlock {
                      __typename
                      page {
                        url
                      }
                    }
                    ... on CMS_ShowPopupBlock {
                      __typename
                      description
                      title
                    }
                  }
                }
                transitionalCtaLabel {
                  name
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
