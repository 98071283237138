import { getPathname, PpHero } from "@italymondo/core-ui";
import { CMSHeroData } from "./query";

export function formatData(data: CMSHeroData, showAlertBar: boolean): PpHero {
  if (!data) {
    throw new Error("Expected a site object");
  }
  const { page } = data;

  return {
    description: page?.heroDescription || "",
    hasAlertBar: showAlertBar && !!page?.alertBar,
    headline: page?.heroHeadline || "",
    heroType: page?.heroType,
    imageSrc: page?.heroImage?.urlSharp || "",
    primaryButton: page?.heroPrimaryButton
      ? {
          title: page?.heroPrimaryButton?.title,
          url: getPathname(page?.heroPrimaryButton?.page?.url),
        }
      : null,
    secondaryButton: page?.heroSecondaryButton
      ? {
          title: page?.heroSecondaryButton?.title,
          url: getPathname(page?.heroSecondaryButton?.page?.url),
        }
      : null,
    verticalText: page?.heroVerticalText?.name || "",
  };
}
