import { graphql, useStaticQuery } from "gatsby";
import { HeroHomepageQuery } from "./query.generated";

export type CMSHeroData = HeroHomepageQuery["cms"]["site"];

export function useStaticHeroQuery(): CMSHeroData {
  const data = useStaticQuery<HeroHomepageQuery>(
    graphql`
      query HeroHomepage {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                __typename
                alertBar {
                  anchorText
                }
                heroDescription
                heroHeadline
                heroImage {
                  alt
                  url
                  urlSharp {
                    childImageSharp {
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                }
                heroPrimaryButton {
                  page {
                    url
                  }
                  title
                }
                heroSecondaryButton {
                  page {
                    url
                  }
                  title
                }
                heroType
                heroVerticalText {
                  name
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms.site;
}
