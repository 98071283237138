import React from "react";
import { Metrics as MetricsComponent } from "@italymondo/core-ui";
import { useStaticMetricsQuery } from "./query";

export function Metrics(): React.ReactElement | null {
  const data = useStaticMetricsQuery();

  if (!data) {
    return null;
  }

  return <MetricsComponent metrics={data.page?.metrics.map((m) => m.metric)} />;
}
