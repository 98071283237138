import { graphql, useStaticQuery } from "gatsby";
import { HomepageAboutQuery } from "./query.generated";

export type CMSAboutData = HomepageAboutQuery["cms"];

export function useStaticAboutQuery(): CMSAboutData {
  const data = useStaticQuery<HomepageAboutQuery>(
    graphql`
      query HomepageAbout {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                aboutCta {
                  page {
                    url
                  }
                  title
                }
                aboutMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(placeholder: BLURRED, width: 900)
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                aboutMediaPlacement
                aboutParagraph
                aboutSectionName
                aboutTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
