import { graphql, useStaticQuery } from "gatsby";
import { HomepageIntroductionQuery } from "./query.generated";

export type CMSIntroductionData = HomepageIntroductionQuery["cms"];

export function useStaticHeroQuery(): CMSIntroductionData {
  const data = useStaticQuery<HomepageIntroductionQuery>(
    graphql`
      query HomepageIntroduction {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                introMedia {
                  ... on CMS_MediaImagesBlock {
                    __typename
                    images {
                      image {
                        alt
                        url
                        urlSharp {
                          childImageSharp {
                            gatsbyImageData(
                              placeholder: BLURRED
                              width: 950
                              height: 650
                            )
                          }
                        }
                      }
                    }
                  }
                  ... on CMS_TestimonialsBlock {
                    __typename
                    testimonials {
                      testimonial {
                        date
                        longMessage
                        name
                        photo {
                          alt
                          url
                          urlSharp {
                            childImageSharp {
                              gatsbyImageData(
                                height: 60
                                width: 60
                                placeholder: BLURRED
                              )
                            }
                          }
                        }
                        sourceDetail {
                          logo {
                            title
                            url
                          }
                          url
                        }
                      }
                    }
                  }
                }
                introMediaPlacement
                introParagraph
                introShadowHeader
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
