import { graphql, useStaticQuery } from "gatsby";
import { HomepageContactFormQuery } from "./query.generated";

export type CMSContactFormData = HomepageContactFormQuery["cms"];

export function useStaticContactFormQuery(): CMSContactFormData {
  const data = useStaticQuery<HomepageContactFormQuery>(
    graphql`
      query HomepageContactForm {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                contactFormSectionName
                footerContactForm {
                  confirmationPage {
                    url
                  }
                  description
                  title
                }
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
