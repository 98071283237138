import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { Services as ServicesComponent } from "../../../components";
import { useStaticServicesQuery } from "./query";

export function Services(): React.ReactElement | null {
  const data = useStaticServicesQuery();

  if (!data) {
    return null;
  }

  const services = data.site?.page?.services.map((s) => ({
    image: {
      alt: s.service.listingImage?.alt || "",
      src: s.service.listingImage?.urlSharp || "",
    },
    label: s.service?.slug || "",
    link: {
      label: s.service.listingLinkText,
      url: getPathname(s.service.url),
    },
    summary: s.service?.listingSummary,
    title: s.service?.listingTitle,
  }));

  return (
    <ServicesComponent
      description={data.site?.page?.servicesDescription || ""}
      sectionName={data.site?.page?.servicesSectionName || ""}
      services={services}
      title={data.site?.page?.servicesTitle || ""}
    />
  );
}
