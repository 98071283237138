import React from "react";
import { getPathname } from "@italymondo/core-ui";
import { graphql } from "gatsby";
import { Layout, SEO } from "../components";
import {
  About,
  ContactForm,
  Hero,
  Introduction,
  Metrics,
  OurProcess,
  Services,
  TestimonialWall,
  TransitionalCTA,
} from "../sections/Homepage";
import { HomepageQuery } from "./index.generated";

type PpIndexPage = {
  data: HomepageQuery;
  location?: {
    pathname?: string;
  };
};

function IndexPage({
  data: {
    cms: { site },
  },
  location,
}: PpIndexPage): React.ReactElement {
  const alertBarData = site.page?.alertBar?.message
    ? {
        link: {
          text: site.page?.alertBar?.anchorText || "",
          url: getPathname(site.page?.alertBar?.page.url) || "",
        },
        text: site.page?.alertBar?.message || "",
      }
    : null;

  console.log("TEST deploy");

  return (
    <Layout alertBar={alertBarData} showProgressBar={site.page?.progressBar}>
      <SEO
        description={site.page?.listingSummary || ""}
        image={site.page?.listingImage?.url}
        location={location}
        title={site.page?.listingTitle || ""}
      />
      <Hero />
      <Introduction />
      <Services />
      <About />
      <Metrics />
      <TransitionalCTA />
      <OurProcess />
      {/* <TestimonialWall /> */}
      <ContactForm />
    </Layout>
  );
}

export default IndexPage;

export const query = graphql`
  query Homepage {
    cms {
      site(hostname: "www.italymondo.com") {
        page(slug: "italymondo") {
          ... on CMS_ItalyMondoHomePage {
            alertBar {
              anchorText
              message
              page {
                url
              }
            }
            heroImage {
              url
            }
            listingImage {
              url
            }
            listingSummary
            listingTitle
            progressBar
          }
        }
      }
    }
  }
`;
