import { graphql, useStaticQuery } from "gatsby";
import { ServicesQuery } from "./query.generated";

export type CMSServicesData = ServicesQuery["cms"];

export function useStaticServicesQuery(): CMSServicesData {
  const data = useStaticQuery<ServicesQuery>(
    graphql`
      query Services {
        cms {
          site(hostname: "www.italymondo.com") {
            page(slug: "italymondo") {
              ... on CMS_ItalyMondoHomePage {
                services {
                  service {
                    listingImage {
                      alt
                      url
                      urlSharp {
                        childImageSharp {
                          gatsbyImageData(
                            width: 550
                            layout: CONSTRAINED
                            placeholder: BLURRED
                          )
                        }
                      }
                    }
                    listingLinkText
                    listingSummary
                    listingTitle
                    slug
                    url
                  }
                }
                servicesDescription
                servicesSectionName
                servicesTitle
              }
            }
          }
        }
      }
    `
  );
  return data.cms;
}
