import React from "react";
import { Hero as HeroComponent, useStore } from "@italymondo/core-ui";
import { formatData } from "./config";
import { useStaticHeroQuery } from "./query";

export function Hero(): React.ReactElement | null {
  const data = useStaticHeroQuery();
  const { showAlertBar } = useStore();

  if (!data) {
    return null;
  }

  const heroData = formatData(data, showAlertBar);

  return <HeroComponent {...heroData} />;
}
