import React from "react";
import {
  bookACallIFrame,
  TransitionalCTA as TransitionalCTAComponent,
} from "@italymondo/core-ui";
import { useStaticTransitionalCTAQuery } from "./query";

export function TransitionalCTA(): React.ReactElement | null {
  const data = useStaticTransitionalCTAQuery();

  if (!data) {
    return null;
  }

  function handleSubmit(
    e: React.FormEvent<HTMLInputElement>,
    value: string
  ): void {
    e.preventDefault();
    // eslint-disable-next-line no-console
    console.log("submission: ", value);
  }

  return (
    <TransitionalCTAComponent
      action={data.page?.transitionalCta?.action[0]}
      badgeText={data.page?.transitionalCtaLabel?.name || ""}
      buttonText={data.page?.transitionalCta?.buttonText}
      description={data.page?.transitionalCta?.description || ""}
      popupContent={bookACallIFrame}
      shadowImage={data.page?.transitionalCta?.shadowImage?.url}
      title={data.page?.transitionalCta?.title || ""}
      onSubmit={handleSubmit}
    />
  );
}
